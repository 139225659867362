import LoginIcon from "@mui/icons-material/Login";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function StaffRow({ record, shadowLoginToMerchantStaff }) {
  return (
    <>
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <Typography variant="body2">{record.merchant.businessName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.phone ? record.phone : "-"}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {record.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <IconButton
            disabled={record.status !== "verified" && record.status !== "pending"}
            onClick={() => shadowLoginToMerchantStaff(record.id)}
          >
            <LoginIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

StaffRow.defaultProps = {
  record: {
    id: null,
    name: "",
    firstName: "",
    lastName: "",
    phone: "",
    status: "",
    rejections: [],
    bank: {
      name: "",
      account: "",
      holderName: "",
    },
  },
};

StaffRow.propTypes = {
  shadowLoginToMerchantStaff: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    merchant: PropTypes.shape({
      businessName: PropTypes.string,
    }),
  }),
};

export default StaffRow;
