import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React from "react";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormFieldDebounceText from "../../../../components/forms/form-field-debounce-text.component";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import MerchantListAutoComplete from "../../fitness-class/components/merchant-list-autocomplete.component";

function FilterOption() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <PaddedView>
                      <MerchantListAutoComplete
                        name="merchantIds"
                        placeholder="Merchants"
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <PaddedView>
                  <FormFieldDebounceText name="q" placeholder="Search for staff name" />
                </PaddedView>
              </Grid>

              <Grid item xs={6}>
                <PaddedView>
                  <FormMultiSelect
                    name="statuses"
                    label="Statuses"
                    options={[
                      { label: "New", value: "new" },
                      { label: "Pending", value: "pending" },
                      { label: "Verified", value: "verified" },
                      { label: "Rejected", value: "rejected" },
                    ]}
                  />
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOption;
