import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PrivateRoute from "./private.route";
import PublicRoute from "./public.route";

import ForgotPasswordScreen from "../../features/auth/screens/forgot-password.screen";
import LoginScreen from "../../features/auth/screens/login.screen";
import AdminDashboard from "../layouts/admin-dashboard.layout";

import UserListScreen from "../../features/user/screens/user-list.screen";

import StaffEditScreen from "../../features/staff/screens/staff-edit.screen";
import StaffListScreen from "../../features/staff/screens/staff-list.screen";
import StaffNewScreen from "../../features/staff/screens/staff-new.screen";

import AccDeletionUserList from "../../features/acc-deletion/screens/acc-deletion-user-list.screen";
import AnnouncementDetailScreen from "../../features/announcement/screens/announcement-detail.screen";
import AnnouncementListScreen from "../../features/announcement/screens/announcement-list.screen";
import NewAnnouncementScreen from "../../features/announcement/screens/new-announcement.screen";
import AttachmentScreen from "../../features/attachments/screens/attachments.screen";
import HealthCategoryCreateScreen from "../../features/category/screens/health-category-create.screen";
import HealthCategoryEditScreen from "../../features/category/screens/health-category-edit.screen";
import HealthCategoryListScreen from "../../features/category/screens/health-category-list.screen";
import ClassCategoryCreateScreen from "../../features/category/screens/class-category-create.screen";
import ClassCategoryEditScreen from "../../features/category/screens/class-category-edit.screen";
import ClassCategoryListScreen from "../../features/category/screens/class-category-list.screen";
import EventCategoryCreateScreen from "../../features/category/screens/event/event-category-create.screen";
import EventCategoryEditScreen from "../../features/category/screens/event/event-category-edit.screen";
import EventCategoryListScreen from "../../features/category/screens/event/event-category-list.screen";
import WellnessCategoryCreateScreen from "../../features/category/screens/wellness-category-create.screen";
import WellnessCategoryEditScreen from "../../features/category/screens/wellness-category-edit.screen";
import WellnessCategoryListScreen from "../../features/category/screens/wellness-category-list.screen";
import EarningListScreen from "../../features/earning/screens/earning-list.screen";
import MerchantLeadsEditScreen from "../../features/lead/screens/merchant-leads-edit.screen";
import MerchantLeadsListScreen from "../../features/lead/screens/merchant-leads-list.screen";
import BannerCreateScreen from "../../features/marketing/screens/banners-marketing-create.screen";
import BannerEditScreen from "../../features/marketing/screens/banners-marketing-edit.screen";
import BannerListScreen from "../../features/marketing/screens/banners-marketing-list.screen";
import MerchantFitnessClassListScreen from "../../features/merchant/fitness-class/screens/class-list.screen";
import MerchantFitnessClassNewScreen from "../../features/merchant/fitness-class/screens/class-new.screen";
import MerchantFitnessClassSessionEditScreen from "../../features/merchant/fitness-class/session/screens/session-edit.screen";
import MerchantFitnessClassSessionListScreen from "../../features/merchant/fitness-class/session/screens/session-list.screen";
import MerchantFitnessClassSessionNewScreen from "../../features/merchant/fitness-class/session/screens/session-new.screen";
import HourlyBookingListScreen from "../../features/merchant/hourly-booking/screens/hourly-booking-list.screen";
import MerchantPackagesPlanEditScreen from "../../features/merchant/packages/plans/screens/plan-edit.screen";
import MerchantPackagesPlanListScreen from "../../features/merchant/packages/plans/screens/plan-list.screen";
import MerchantPackagesPlanNewScreen from "../../features/merchant/packages/plans/screens/plan-new.screen";
import MerchantPackagesPurchasersListScreen from "../../features/merchant/packages/screens/purchasers-list.screen";
import MerchantBankEditScreen from "../../features/merchant/screens/merchant-bank-edit.screen";
import MerchantBusinessCreateScreen from "../../features/merchant/screens/merchant-business-create.screen";
import MerchantBusinessEditScreen from "../../features/merchant/screens/merchant-business-edit.screen";
import MerchantBusinessListScreen from "../../features/merchant/screens/merchant-business-list.screen";
import MerchantCreateScreen from "../../features/merchant/screens/merchant-create.screen";
import MerchantEditScreen from "../../features/merchant/screens/merchant-edit.screen";
import MerchantListScreen from "../../features/merchant/screens/merchant-list.screen";
import MerchantSubscriptionsPlanEditScreen from "../../features/merchant/subscription/plans/screens/plan-edit.screen";
import MerchantSubscriptionsPlanListScreen from "../../features/merchant/subscription/plans/screens/plan-list.screen";
import MerchantSubscriptionsPlanNewScreen from "../../features/merchant/subscription/plans/screens/plan-new.screen";
import MerchantSubscriptionSubscribersListScreen from "../../features/merchant/subscription/screens/subscribers-list.screen";
import NotificationHistoryScreen from "../../features/notification/screens/notification-history.screen";
import NotificationScreen from "../../features/notification/screens/notification.screen";
import PopUpDetailScreen from "../../features/pop-up/screens/pop-up-detail.screen";
import PopUpListScreen from "../../features/pop-up/screens/pop-up-list.screen";
import PromoCodeCreateScreen from "../../features/promo-code/screens/promo-code-create.screen";
import PromoCodeEditScreen from "../../features/promo-code/screens/promo-code-edit.screen";
import PromoCodeListScreen from "../../features/promo-code/screens/promo-code-list.screen";
import StatementDetailScreen from "../../features/statement/screens/statement-details.screen";
import StatementListScreen from "../../features/statement/screens/statement-list.screen";
import UserBookingDetailScreen from "../../features/user/screens/user-booking-detail.screen";
import UserEditScreen from "../../features/user/screens/user-edit.screen";
import CreditTransactionsListScreen from "../../features/credit/transactions/screens/credit-transactions-list.screen";
import CreditTransactionsNewScreen from "../../features/credit/transactions/screens/credit-transactions-new.screen";
import CreditTransactionsDeductScreen from "../../features/credit/transactions/screens/credit-transactions-deduct.screen";
import HighlightsListScreen from "../../features/highlight/screens/highlights-list.screen";
import HighlightNewScreen from "../../features/highlight/screens/highlights-new.screen";
import MerchantStaffListScreen from "../../features/merchant/staff/screen/merchant-staff-list.screen";

function index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot_password" element={<ForgotPasswordScreen />} />
        </Route>

        <Route element={<AdminDashboard />}>
          <Route element={<PrivateRoute />}>
            <Route path="/users" element={<UserListScreen />} />
            <Route path="/users/booking_details/:id" element={<UserBookingDetailScreen />} />
            <Route path="/users/:id" element={<UserEditScreen />} />

            <Route path="/staffs/list" element={<StaffListScreen />} />
            <Route path="/staffs/:staff_id/edit" element={<StaffEditScreen />} />
            <Route path="/staffs/new" element={<StaffNewScreen />} />

            <Route path="/promo_codes/details/edit" element={<PromoCodeEditScreen />} />
            <Route path="/promo_codes/new" element={<PromoCodeCreateScreen />} />
            <Route path="/promo_codes/list" element={<PromoCodeListScreen />} />
            <Route path="/category/fitness/new" element={<ClassCategoryCreateScreen />} />
            <Route path="/category/fitness/list" element={<ClassCategoryListScreen />} />
            <Route path="/category/fitness/:id/edit" element={<ClassCategoryEditScreen />} />
            <Route path="/category/wellness/new" element={<WellnessCategoryCreateScreen />} />
            <Route path="/category/wellness/list" element={<WellnessCategoryListScreen />} />
            <Route path="/category/wellness/:id/edit" element={<WellnessCategoryEditScreen />} />
            <Route path="/category/health/new" element={<HealthCategoryCreateScreen />} />
            <Route path="/category/health/list" element={<HealthCategoryListScreen />} />
            <Route path="/category/health/:id/edit" element={<HealthCategoryEditScreen />} />
            <Route path="/category/event/new" element={<EventCategoryCreateScreen />} />
            <Route path="/category/event/list" element={<EventCategoryListScreen />} />
            <Route path="/category/event/:id/edit" element={<EventCategoryEditScreen />} />
            <Route path="/marketing/banners/new" element={<BannerCreateScreen />} />
            <Route path="/marketing/banners/list" element={<BannerListScreen />} />
            <Route path="/marketing/banners/:id/edit" element={<BannerEditScreen />} />

            <Route path="/announcement/new" element={<NewAnnouncementScreen />} />
            <Route path="/announcement/list" element={<AnnouncementListScreen />} />
            <Route
              path="/announcement/details/:announcementId"
              element={<AnnouncementDetailScreen />}
            />

            <Route path="/notifications" element={<NotificationScreen />} />
            <Route path="/notifications/history" element={<NotificationHistoryScreen />} />

            <Route path="/pop_up" element={<PopUpListScreen />} />
            <Route path="/pop_up/details/:popUpId/edit" element={<PopUpDetailScreen />} />

            <Route path="/attachments" element={<AttachmentScreen />} />

            <Route path="/acc_deletion/list" element={<AccDeletionUserList />} />

            <Route
              path="/merchants/packages/plans/list"
              element={<MerchantPackagesPlanListScreen />}
            />
            <Route
              path="/merchants/packages/plans/:plan_id/edit"
              element={<MerchantPackagesPlanEditScreen />}
            />
            <Route
              path="/merchants/packages/plans/new"
              element={<MerchantPackagesPlanNewScreen />}
            />
            <Route
              path="/merchants/packages/purchasers"
              element={<MerchantPackagesPurchasersListScreen />}
            />
            <Route
              path="/merchants/subscriptions/plans/list"
              element={<MerchantSubscriptionsPlanListScreen />}
            />
            <Route
              path="/merchants/subscriptions/plans/:plan_id/edit"
              element={<MerchantSubscriptionsPlanEditScreen />}
            />
            <Route
              path="/merchants/subscriptions/plans/new"
              element={<MerchantSubscriptionsPlanNewScreen />}
            />
            <Route
              path="/merchants/subscriptions/subscribers"
              element={<MerchantSubscriptionSubscribersListScreen />}
            />
            <Route
              path="/merchants/fitness_class/list"
              element={<MerchantFitnessClassListScreen />}
            />
            <Route
              path="/merchants/fitness_class/new"
              element={<MerchantFitnessClassNewScreen />}
            />
            <Route
              path="/merchants/fitness_class/sessions/list"
              element={<MerchantFitnessClassSessionListScreen />}
            />
            <Route
              path="/merchants/fitness_class/sessions/:session_id/edit"
              element={<MerchantFitnessClassSessionEditScreen />}
            />
            <Route
              path="/merchants/fitness_class/sessions/new"
              element={<MerchantFitnessClassSessionNewScreen />}
            />

            <Route path="/merchants/hourly_booking/list" element={<HourlyBookingListScreen />} />
            <Route path="/merchants/create" element={<MerchantCreateScreen />} />
            <Route path="/merchants/list" element={<MerchantListScreen />} />
            <Route path="/merchants/staffs/list" element={<MerchantStaffListScreen />} />
            <Route path="/merchants/edit" element={<MerchantEditScreen />} />
            <Route path="/merchants/:merchantId/banks" element={<MerchantBankEditScreen />} />
            <Route path="/merchants/business/create" element={<MerchantBusinessCreateScreen />} />
            <Route path="/merchants/business/list" element={<MerchantBusinessListScreen />} />
            <Route path="/merchants/business/edit" element={<MerchantBusinessEditScreen />} />
            <Route path="/statements/list" element={<StatementListScreen />} />
            <Route path="/statements/detail" element={<StatementDetailScreen />} />
            <Route path="/earnings/list" element={<EarningListScreen />} />
            <Route path="/leads/merchants" element={<MerchantLeadsListScreen />} />
            <Route path="/leads/merchants/edit" element={<MerchantLeadsEditScreen />} />
            <Route path="/credits/transactions/new" element={<CreditTransactionsNewScreen />} />
            <Route
              path="/credits/transactions/deduct"
              element={<CreditTransactionsDeductScreen />}
            />
            <Route path="/credits/transactions/list" element={<CreditTransactionsListScreen />} />
            <Route path="/highlight/list" element={<HighlightsListScreen />} />
            <Route path="/highlight/new" element={<HighlightNewScreen />} />

            <Route path="/" element={<EarningListScreen />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default index;
