import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import TableLoader from "../../../../components/tables/table-loader.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  getStaffs,
  merchantStaffSelector,
  shadowLoginAsMerchantStaff,
} from "../../../../services/merchant/staff/staff-slice.service";
import FilterOption from "../components/filter-option.component";
import StaffListTableIndex from "../components/staff-list-table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  startDate: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
  statuses: Yup.array().nullable().label("Statuses"),
});

export default function MerchantStaffListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [merchantIds, setMerchantIds] = useState([]);
  const { getStaffsObj } = useSelector(merchantStaffSelector);

  // useEffect(() => {
  //   dispatch(getStaffs({ q, page })).then(({ meta, error }) => {
  //     if (meta.requestStatus === "rejected") {
  //       createSnackBar({ type: "error", message: error.message });
  //     }
  //   });
  // }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getStaffs({
        q,
        page: newPage,
        statuses: statuses.join(","),
        merchantIds: merchantIds.map((item) => item.id).join(","),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onHandleSubmit = (values) => {
    setQ(values.q);
    setPage(0);
    setStatuses(values.statuses);
    setMerchantIds(values.merchantIds);
    dispatch(
      getStaffs({
        q: values.q,
        page: 0,
        statuses: values.statuses.join(","),
        merchantIds: values.merchantIds.map((item) => item.id).join(","),
      }),
    );
  };

  const shadowLoginToMerchantStaff = (staffId) => {
    dispatch(shadowLoginAsMerchantStaff(staffId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        window.open(
          `https://merchant.katch.asia/shadow_login_as_staff?authToken=${payload.data.authToken}&isStaff=true`,
          "_blank",
        );
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const renderDataRecords = () => {
    const records = getStaffsObj.data;

    return (
      <>
        {records.items.length !== 0 ? (
          <PaddedView>
            <StaffListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              records={records}
              shadowLoginToMerchantStaff={shadowLoginToMerchantStaff}
            />
          </PaddedView>
        ) : (
          <Typography>No merchant staffs.</Typography>
        )}
      </>
    );
  };

  return (
    <Box>
      <Form
        initialValues={{
          q: "",
          statuses: [],
          merchantIds: [],
        }}
        onSubmit={onHandleSubmit}
        validationSchema={validationSchema}
      >
        <FilterOption />
      </Form>
      <Spacer size="l" />
      {getStaffsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </Box>
  );
}
