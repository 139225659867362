import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TableHeader from "../../../components/tables/table-header.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import EarningRow from "../components/earning-row.component";
import TableLoader from "../loader/table-loader.component";
import {
  earningSelector,
  getEarningList,
  getEarningSummary,
} from "../../../services/earning/earning-slice.service";
import FilterOptions from "../components/filter-options.component";
import TableSort from "../../../components/tables/table-sort.component";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().label("Start At").typeError("Invalid date"),
  endDate: Yup.date().label("End At").nullable(),
});

const DetailCardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `1px 2px 5px ${theme.palette.colors.loading.foregroundColor}`,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "15px",
  paddingRight: "15px",
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function EarningListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getEarningListObj, getEarningSummaryObj } = useSelector(earningSelector);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const columnMapping = {
    "Statement ID": "statementId",
    "Merchant Business Name": "merchantBusinessName",
    "Revenue (RM)": "revenue",
    "Merchant Sum (RM)": "merchantSum",
    "Platform Commission (RM)": "platformCommission",
    "Merchant Promo Codes (RM)": "merchantPromoCode",
    "Platform Promo Codes (RM)": "platformPromoCode",
    "Gross Profit (RM)": "grossProfit",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onSearchEarning = (values) => {
    setPage(1);
    let startAt = "";
    let endAt = "";
    if (values.startDate !== null) {
      startAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      endAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }

    dispatch(getEarningSummary({ startAt, endAt })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });

    dispatch(
      getEarningList({
        startAt,
        endAt,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    let startAt = "";
    let endAt = "";
    if (formRef.current.values.startDate !== null) {
      startAt = format(new Date(formRef.current.values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (formRef.current.values.endDate !== null) {
      endAt = format(new Date(formRef.current.values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      getEarningList({
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onSearchEarning(formRef.current.values);
  }, []);

  const renderSummary = () => {
    if (getEarningSummaryObj.status === "succeeded") {
      return (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Revenue: </Typography>
                  <Typography sx={{ color: theme.palette.colors.text.secondary }} variant="h6">
                    RM {getEarningSummaryObj.data.revenue}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Merchant Sum: </Typography>
                  <Typography sx={{ color: theme.palette.colors.text.secondary }} variant="h6">
                    RM {getEarningSummaryObj.data.merchantSum}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Merchant Promo Codes: </Typography>
                  <Typography sx={{ color: theme.palette.colors.text.secondary }} variant="h6">
                    RM {getEarningSummaryObj.data.merchantPromoCodes}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Platform Promo Codes: </Typography>
                  <Typography sx={{ color: theme.palette.colors.text.secondary }} variant="h6">
                    RM {getEarningSummaryObj.data.platformPromoCodes}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Platform Commission: </Typography>
                  <Typography sx={{ color: theme.palette.colors.text.secondary }} variant="h6">
                    RM {getEarningSummaryObj.data.platformCommission}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography variant="h6">Gross Profit: </Typography>
                  {getEarningSummaryObj.data.grossProfit &&
                  getEarningSummaryObj.data.grossProfit.includes("-") ? (
                    <Typography variant="h6" sx={{ color: theme.palette.colors.text.error }}>
                      - RM {getEarningSummaryObj.data.grossProfit.replace("-", "")}
                    </Typography>
                  ) : (
                    <Typography variant="h6" sx={{ color: theme.palette.colors.text.success }}>
                      RM {getEarningSummaryObj.data.grossProfit}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  <Skeleton />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  <Skeleton />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  <Skeleton />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Typography variant="h6" sx={{ color: theme.palette.colors.text.secondary }}>
                  <Skeleton />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Skeleton />
                <Typography variant="h6" sx={{ color: theme.palette.colors.text.secondary }}>
                  <Skeleton />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getEarningListObj.data && getEarningListObj.status === "succeeded") {
      records = { ...getEarningListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    if (getEarningListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Statement ID",
                "Merchant Business Name",
                "Revenue (RM)",
                "Merchant Sum (RM)",
                "Platform Commission (RM)",
                "Merchant Promo Codes (RM)",
                "Platform Promo Codes (RM)",
                "Gross Profit (RM)",
              ]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>
              {records?.items.map((item) => (
                <EarningRow record={item} key={item.statementId} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getEarningListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView>
      <Form
        innerRef={formRef}
        onSubmit={onSearchEarning}
        validationSchema={validationSchema}
        initialValues={{
          startDate: format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd"),
          endDate: new Date(),
        }}
      >
        <FilterOptions />
      </Form>
      <Spacer position="top" size="l" />
      <DetailCardContainer>{renderSummary()}</DetailCardContainer>
      <Spacer position="top" size="l" />
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Earnings List</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {renderContent()}
      </TableWrapper>
    </PaddedView>
  );
}
