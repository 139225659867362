import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  styled,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import StaffRow from "./staff-row.component";
import TableSort from "../../../../components/tables/table-sort.component";
import { merchantStaffSelector } from "../../../../services/merchant/staff/staff-slice.service";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
}));

function StaffListTableIndex({ handlePageChange, page, records, shadowLoginToMerchantStaff }) {
  const columnMapping = {
    "Merchant Business Name": "merchant.businessName",
    "Full Name": "name",
    "Phone Number": "phone",
    "Account Approval": "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let sortedRecords = null;
    sortedRecords = { ...records };

    const sortedData = [...sortedRecords.items].sort((a, b) => {
      if (sortColumn) {
        const columnA = parseValue(getColumnValue(a, sortColumn));
        const columnB = parseValue(getColumnValue(b, sortColumn));

        // Handle boolean values
        if (typeof columnA === "boolean" && typeof columnB === "boolean") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle numeric values without converting to strings
        if (typeof columnA === "number" && typeof columnB === "number") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle string values
        const stringColumnA = typeof columnA === "string" ? columnA : "";
        const stringColumnB = typeof columnB === "string" ? columnB : "";

        return sortOrder === "asc"
          ? stringColumnA.localeCompare(stringColumnB)
          : stringColumnB.localeCompare(stringColumnA);
      }
      return 0; // No sorting if sortColumn is null
    });

    // Replace the original items array with the sortedData
    sortedRecords.items = sortedData;

    return sortedRecords;
  };
  const sortedRecords = getProcessedRecord();
  const { getStaffsObj } = useSelector(merchantStaffSelector);

  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">Merchant List</Typography>
      </TableTopContainer>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerCells={[
            "Merchant Business Name",
            "Full Name",
            "Phone Number",
            "Account Approval",
            "Action",
          ]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />

        {records.items &&
          sortedRecords.items.map((record) => (
            <TableBody key={record.id}>
              <StaffRow record={record} shadowLoginToMerchantStaff={shadowLoginToMerchantStaff} />
            </TableBody>
          ))}

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={getStaffsObj.data?.pagination.perPage || 1}
              count={getStaffsObj.data?.pagination.totalItems || 1}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

StaffListTableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  shadowLoginToMerchantStaff: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNo: PropTypes.string,
        status: PropTypes.string,
        rejections: PropTypes.arrayOf(
          PropTypes.shape({
            reason: PropTypes.string,
            rejectedBy: PropTypes.string,
          }),
        ),
        bank: PropTypes.shape({
          name: PropTypes.string,
          account: PropTypes.string,
          holderName: PropTypes.string,
        }),
        businesses: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            location: PropTypes.string,
            status: PropTypes.string,
          }),
        ),
        deletable: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
};

export default StaffListTableIndex;
