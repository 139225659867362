import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const createMerchant = createAsyncThunk("merchant/create", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants`, payload);
  return response;
});

export const getMerchants = createAsyncThunk("merchants/index", async (payload) => {
  const { q, createdStartAt, createdEndAt, statuses } = payload;
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants?page=${payload.page + 1}${
      q ? "&q=".concat(q) : ""
    }${createdStartAt ? "&createdStartAt=".concat(createdStartAt) : ""}${
      createdEndAt ? "&createdEndAt=".concat(createdEndAt) : ""
    }${statuses ? "&statuses=".concat(statuses) : ""}
    `,
  );
  return response;
});

export const getMerchantDetail = createAsyncThunk("merchant/detail", async (merchantId) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}`,
  );
  return response;
});

export const getMerchantAutoCompleteSuggestion = createAsyncThunk(
  "merchant/auto_complete",
  async (payload) => {
    const { q, page } = payload;

    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/auto_complete?${
        page ? "page=".concat(page) : "page=1"
      }${q ? "&q=".concat(q) : ""}`,
    );
    return response;
  },
);

export const shadowLoginAsMerchant = createAsyncThunk(
  "merchant/shadow_login",
  async (merchantId) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}/shadow_login`,
    );
    return response;
  },
);

export const updateMerchant = createAsyncThunk("merchant/update", async (payload) => {
  const {
    merchantId,
    commissionPercentage,
    businessName,
    firstName,
    lastName,
    bankName,
    accountNo,
    accountHolderName,
    phone,
    email,
    status,
    password,
    rejectReason,
  } = payload;
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}`,
    {
      commissionPercentage,
      businessName,
      firstName,
      lastName,
      bankName,
      accountNo,
      accountHolderName,
      phone,
      email,
      status,
      password,
      rejectReason,
    },
  );
  return response;
});

export const uploadMerchantLogo = createAsyncThunk("merchant/upload/logo", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/logo`,
    { image: payload.image },
  );
  return response;
});

export const deleteMerchant = createAsyncThunk("merchant/delete", async (merchantId) => {
  const response = delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}`,
  );
  return response;
});

export const deleteMerchantLogo = createAsyncThunk("merchant/delete/logo", async (merchantId) => {
  const response = delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${merchantId}/logo`,
  );
  return response;
});

export const downloadMerchantsEmailList = createAsyncThunk(
  "merchants/email_list",
  async (payload) => {
    const { q, createdStartAt, createdEndAt, statuses } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/email_list?${
        q ? "q=".concat(q) : ""
      }${createdStartAt ? "&createdStartAt=".concat(createdStartAt) : ""}${
        createdEndAt ? "&createdEndAt=".concat(createdEndAt) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}
    `,
    );
    return response;
  },
);

const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    createMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadMerchantLogoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMerchantLogoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    shadowLoginAsMerchantObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    downloadMerchantsEmailListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [createMerchant.pending]: (state) => {
      state.createMerchantObj.status = "pending";
    },
    [createMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMerchantObj.status = "succeeded";
      state.createMerchantObj.data = data;
      state.createMerchantObj.successMessage = message;
    },
    [createMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMerchantObj.status = "failed";
      state.createMerchantObj.errorMessage = message;
    },
    [getMerchants.pending]: (state) => {
      state.getMerchantsObj.status = "pending";
    },
    [getMerchants.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantsObj.status = "succeeded";
      state.getMerchantsObj.data = data;
      state.getMerchantsObj.successMessage = message;
    },
    [getMerchants.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantsObj.status = "failed";
      state.getMerchantsObj.errorMessage = message;
    },
    [getMerchantDetail.pending]: (state) => {
      state.getMerchantDetailObj.status = "pending";
    },
    [getMerchantDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantDetailObj.status = "succeeded";
      state.getMerchantDetailObj.data = data;
      state.getMerchantDetailObj.successMessage = message;
    },
    [getMerchantDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantDetailObj.status = "failed";
      state.getMerchantDetailObj.errorMessage = message;
    },
    [getMerchantAutoCompleteSuggestion.pending]: (state) => {
      state.getMerchantAutoCompleteSuggestionObj.status = "pending";
    },
    [getMerchantAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantAutoCompleteSuggestionObj.status = "succeeded";
      state.getMerchantAutoCompleteSuggestionObj.data = data;
      state.getMerchantAutoCompleteSuggestionObj.successMessage = message;
    },
    [getMerchantAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantAutoCompleteSuggestionObj.status = "failed";
      state.getMerchantAutoCompleteSuggestionObj.errorMessage = message;
    },
    [updateMerchant.pending]: (state) => {
      state.updateMerchantObj.status = "pending";
    },
    [updateMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMerchantObj.status = "succeeded";
      state.updateMerchantObj.data = data;
      state.updateMerchantObj.successMessage = message;

      state.getMerchantDetailObj.data = data;
    },
    [updateMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantObj.status = "failed";
      state.updateMerchantObj.errorMessage = message;
    },
    [uploadMerchantLogo.pending]: (state) => {
      state.uploadMerchantLogoObj.status = "pending";
    },
    [uploadMerchantLogo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadMerchantLogoObj.status = "succeeded";
      state.uploadMerchantLogoObj.data = data;
      state.uploadMerchantLogoObj.successMessage = message;

      state.getMerchantDetailObj.data = data;
    },
    [uploadMerchantLogo.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadMerchantLogoObj.status = "failed";
      state.uploadMerchantLogoObj.errorMessage = message;
    },
    [deleteMerchant.pending]: (state) => {
      state.deleteMerchantObj.status = "pending";
    },
    [deleteMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteMerchantObj.status = "succeeded";
      state.deleteMerchantObj.data = data;
      state.deleteMerchantObj.successMessage = message;

      state.getMerchantsObj.data = data;
    },
    [deleteMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMerchantObj.status = "failed";
      state.deleteMerchantObj.errorMessage = message;
    },
    [deleteMerchantLogo.pending]: (state) => {
      state.deleteMerchantLogoObj.status = "pending";
    },
    [deleteMerchantLogo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteMerchantLogoObj.status = "succeeded";
      state.deleteMerchantLogoObj.data = data;
      state.deleteMerchantLogoObj.successMessage = message;

      state.getMerchantsObj.data = data;
    },
    [deleteMerchantLogo.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMerchantLogoObj.status = "failed";
      state.deleteMerchantLogoObj.errorMessage = message;
    },
    [shadowLoginAsMerchant.pending]: (state) => {
      state.shadowLoginAsMerchantObj.status = "pending";
    },
    [shadowLoginAsMerchant.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.shadowLoginAsMerchantObj.status = "succeeded";
      state.shadowLoginAsMerchantObj.data = data;
      state.shadowLoginAsMerchantObj.successMessage = message;
    },
    [shadowLoginAsMerchant.rejected]: (state, action) => {
      const { message } = action.error;

      state.shadowLoginAsMerchantObj.status = "failed";
      state.shadowLoginAsMerchantObj.errorMessage = message;
    },
    [downloadMerchantsEmailList.pending]: (state) => {
      state.downloadMerchantsEmailListObj.status = "pending";
    },
    [downloadMerchantsEmailList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.downloadMerchantsEmailListObj.status = "succeeded";
      state.downloadMerchantsEmailListObj.data = data;
      state.downloadMerchantsEmailListObj.successMessage = message;
    },
    [downloadMerchantsEmailList.rejected]: (state, action) => {
      const { message } = action.error;

      state.downloadMerchantsEmailListObj.status = "failed";
      state.downloadMerchantsEmailListObj.errorMessage = message;
    },
  },
});

export default merchantSlice.reducer;

// state
export const merchantSelector = (state) => state.merchant;
