import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../../api.service";

export const getStaffAutoComplete = createAsyncThunk(
  "employees/merchants/staffs/auto_complete",
  async (payload) => {
    const { q, page, merchantId } = payload;
    const response = await getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/employees/merchants/${merchantId}/staffs/auto_complete?page=${page}${
        q ? "&q=".concat(q) : ""
      }`,
    );
    return response;
  },
);

export const getStaffs = createAsyncThunk("employees/merchants/staffs/index", async (payload) => {
  const { q, statuses, page, merchantIds } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/staffs/?page=${page + 1}${
      q ? "&q=".concat(q) : ""
    }${statuses ? "&statuses=".concat(statuses) : ""}${
      merchantIds ? "&merchantIds=".concat(merchantIds) : ""
    }`,
  );
  return response;
});

export const shadowLoginAsMerchantStaff = createAsyncThunk(
  "merchant/staffs/{id}/shadow_login",
  async (staffId) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/staffs/${staffId}/shadow_login`,
    );
    return response;
  },
);

const merchantStaffSlice = createSlice({
  name: "merchantStaff",
  initialState: {
    getStaffAutoCompleteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    shadowLoginAsMerchantStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getStaffAutoComplete.pending]: (state) => {
      state.getStaffAutoCompleteObj.status = "pending";
    },
    [getStaffAutoComplete.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffAutoCompleteObj.status = "succeeded";
      state.getStaffAutoCompleteObj.data = data;
      state.getStaffAutoCompleteObj.successMessage = message;
    },
    [getStaffAutoComplete.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffAutoCompleteObj.status = "failed";
      state.getStaffAutoCompleteObj.errorMessage = message;
    },
    [getStaffs.pending]: (state) => {
      state.getStaffsObj.status = "pending";
    },
    [getStaffs.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffsObj.status = "succeeded";
      state.getStaffsObj.data = data;
      state.getStaffsObj.successMessage = message;
    },
    [getStaffs.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffsObj.status = "failed";
      state.getStaffsObj.errorMessage = message;
    },
    [shadowLoginAsMerchantStaff.pending]: (state) => {
      state.shadowLoginAsMerchantStaffObj.status = "pending";
    },
    [shadowLoginAsMerchantStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.shadowLoginAsMerchantStaffObj.status = "succeeded";
      state.shadowLoginAsMerchantStaffObj.data = data;
      state.shadowLoginAsMerchantStaffObj.successMessage = message;
    },
    [shadowLoginAsMerchantStaff.rejected]: (state, action) => {
      const { message } = action.error;

      state.shadowLoginAsMerchantStaffObj.status = "failed";
      state.shadowLoginAsMerchantStaffObj.errorMessage = message;
    },
  },
});

export default merchantStaffSlice.reducer;

// state
export const merchantStaffSelector = (state) => state.merchantStaff;
